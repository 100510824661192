<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img :src="logoUrl" :alt="appName" width="200"/>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="Forgot password V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Forgot password-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col
                        sm="8"
                        md="6"
                        lg="12"
                        class="px-xl-2 mx-auto"
                >
                    <b-card-title class="mb-1">
                        Şifremi unuttum? 🔒
                    </b-card-title>
                    <b-card-text class="mb-2">
                        E-postanızı girin, size şifrenizi sıfırlamak için talimatlar gönderelim
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form
                                class="auth-forgot-password-form mt-2"
                                @submit.prevent="validationForm"
                        >
                            <b-form-group
                                    label="Email"
                                    label-for="forgot-password-email"
                            >
                                <validation-provider
                                        #default="{ errors }"
                                        name="Email"
                                        rules="required|email"
                                >
                                    <b-form-input
                                            id="forgot-password-email"
                                            v-model="userEmail"
                                            :state="errors.length > 0 ? false:null"
                                            name="forgot-password-email"
                                            placeholder="mustersoft@mustersoft.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                            >
                                Şifremi Sıfırla
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{name:'auth-login'}">
                            <feather-icon icon="ChevronLeftIcon"/>
                            Kullanıcı girişine geri dön
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Forgot password-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {required, email} from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@/libs/axios";
import {$themeConfig} from "@themeConfig";

export default {
    components: {
        VuexyLogo,
        BRow,
        BCol,
        BLink,
        BImg,
        BForm,
        BButton,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BCardText,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: '',
            sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),

            // validation
            required,
            email,
        }
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
        logoUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                this.sideImg = require('@/assets/images/logo/logo_light.png')
                return this.sideImg
            }
            return $themeConfig.app.appLogoImage
        },
        appName() {
            return $themeConfig.app.appName
        },
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!$themeConfig.app.appIsDemo) {
                        axios.post('/auth/reset-password', {email: this.userEmail}).then(response => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Başarılı',
                                    text: 'Şifreniz sıfırlanmıştır.',
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                },
                            })
                        }).catch(error => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Hata',
                                    text: error.response.data.message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Hata',
                                text: 'Demo kullanımda işlem yapılamaz!',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                    }
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.bg-color-dark {
  background-color: #1e2637;
}
</style>
